exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alerts-js": () => import("./../../../src/pages/alerts.js" /* webpackChunkName: "component---src-pages-alerts-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-network-js": () => import("./../../../src/pages/services/network.js" /* webpackChunkName: "component---src-pages-services-network-js" */),
  "component---src-pages-services-software-js": () => import("./../../../src/pages/services/software.js" /* webpackChunkName: "component---src-pages-services-software-js" */),
  "component---src-pages-services-web-js": () => import("./../../../src/pages/services/web.js" /* webpackChunkName: "component---src-pages-services-web-js" */)
}

